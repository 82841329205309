import axios from '@services/axiosInterceptorInstance'
import {
  NEXT_ADDRESS,
  NEXT_CREATE_ADDRESS,
  NEXT_DELETE_ADDRESS,
  NEXT_EDIT_ADDRESS,
} from '@components/utils/constants'
import { getCurrentPage } from '@framework/utils/app-util'
import { matchStrings } from '@framework/utils/parse-util'

interface stateInterface {
  isDeliveryMethodSelected: boolean
  isShippingInformationCompleted: boolean
  isPaymentInformationCompleted: boolean
  shippingInformation: any
  billingInformation: any
  deliveryMethod: any
  isSameAddress: boolean
  selectedPaymentMethod: any
  shippingMethod: any
  storeId: string
  isCNC: boolean
  error: string
  orderResponse: any
  showStripe: boolean
  isPaymentIntent: boolean
  isPaymentWidgetActive: boolean
}

interface actionInterface {
  type?: string
  payload?: any
}

export function asyncHandler() {
  function getAddress() {
    return async (id: string, isChannelStore?: boolean) => {
      const response: any = await axios.post(NEXT_ADDRESS, {
        id,
        isChannelStore,
        currentPage: getCurrentPage(),
      })
      return response?.data
    }
  }
  function updateAddress() {
    return async (data: any) => {
      const response: any = await axios.post(NEXT_EDIT_ADDRESS, data)
      return response?.data
    }
  }
  function createAddress() {
    return async (data: any) => {
      const response: any = await axios.post(NEXT_CREATE_ADDRESS, data)
      return response?.data
    }
  }
  function deleteAddress() {
    return async (data: any) => {
      const response: any = await axios.post(NEXT_DELETE_ADDRESS, data)
      return response?.data
    }
  }
  return {
    getAddress: getAddress(),
    updateAddress: updateAddress(),
    createAddress: createAddress(),
    deleteAddress: deleteAddress(),
  }
}

export function reducer(
  state: stateInterface,
  { type, payload }: actionInterface
) {
  switch (type) {
    case 'SET_SHIPPING_METHOD': {
      return {
        ...state,
        shippingMethod: payload,
      }
    }
    case 'IS_CNC': {
      return {
        ...state,
        storeId: payload,
        isCNC: !state.isCNC,
      }
    }
    case 'SET_PAYMENT_METHOD': {
      return {
        ...state,
        selectedPaymentMethod: payload,
      }
    }
    case 'TOGGLE_DELIVERY_METHOD': {
      return {
        ...state,
        isDeliveryMethodSelected: !state.isDeliveryMethodSelected,
        deliveryMethod: payload || state.deliveryMethod,
      }
    }
    case 'TOGGLE_SHIPPING': {
      return {
        ...state,
        isShippingInformationCompleted: !state.isShippingInformationCompleted,
      }
    }
    case 'TOGGLE_PAYMENT': {
      return {
        ...state,
        isPaymentInformationCompleted: payload,
      }
    }
    case 'SET_SHIPPING_INFORMATION': {
      return {
        ...state,
        shippingInformation: payload,
      }
    }
    case 'SET_BILLING_INFORMATION': {
      return {
        ...state,
        billingInformation: payload,
      }
    }
    case 'SET_SAME_ADDRESS': {
      return {
        ...state,
        isSameAddress: !state.isSameAddress,
      }
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: payload,
      }
    }
    case 'SET_ORDER_RESPONSE': {
      return {
        ...state,
        orderResponse: payload,
      }
    }
    case 'TRIGGER_STRIPE': {
      return {
        ...state,
        showStripe: payload,
      }
    }
    case 'SET_PAYMENT_INTENT': {
      return {
        ...state,
        isPaymentIntent: payload,
      }
    }
    case 'TRIGGER_PAYMENT_WIDGET': {
      return {
        ...state,
        isPaymentWidgetActive: payload,
      }
    }
    default: {
      return state
    }
  }
}

export const getValuesData = (data: any, name: any) => {
  return {
    address1: data?.address1,
    address2: data?.address2,
    city: data?.city,
    state: data?.state,
    firstName: name?.firstName,
    lastName: name?.lastName ?? ' ',
    phoneNo: data?.mobileNumber,
    postCode: data?.pinCode,
    isDefault: data?.useAsDefault,
    isConsentSelected: data?.whtsappUpdated,
    label: matchStrings(data?.categoryName, 'Other', true)
      ? data?.otherAddressType
      : data?.categoryName,
    title: '',
  }
}

export const strVal = (val: string): string => {
  if (val) {
    return val.trim().toLowerCase()
  }
  return ''
}
