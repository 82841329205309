export enum MO_ENGAGE_EVENT {
  ADD_TO_CART = 'ADDTOCART',
  ADD_TO_WISHLIST = 'add_to_wishlist',

  BANNER_CLICK = 'banner_click',
  BEGIN_CHECKOUT = 'Begin_checkout',
  BLOG_CLICK = 'blog_click',

  CANCEL_CONFIRM = 'cancel_confirm',
  CART_COUPON = 'cart coupon',
  CHECKOUT_PAYMODE = 'checkout_paymode',

  FILTER_CLICK = 'filter_click',
  FOOTER_CLICK = 'footer_click',

  LOGIN = 'login',

  NOTIFY_CLICK = 'notify_click',

  OFFER_POPUP = 'offer_popup',

  PDP_ORANGE_OFFER_MODAL = 'PDP orange offer modal',
  PRODUCT_VIEW = 'Product view',
  PURCHASE = 'Purchase',
  PURCHASE_SUMMARY = 'Purchase_summary',

  REFERRER_BANNERS = 'referrer_banners',
  REMOVE_FROM_CART = 'Remove_from_cart',
  REVIEW = 'review',

  SEARCH = 'search',
  SIZES = 'Sizes',

  QUICK_VIEW = 'quick_view',
  WHATSAPP_OPTIN = 'whatsapp_optin',
}
