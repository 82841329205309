export interface Dictionary<T> {
  [key: string]: T
}

export const round = (number: number, precision = 0) => {
  const factor = 10 ** precision
  return Math.round(number * factor) / factor
}

export const isEmpty = (value: any): boolean => {
  if (value == null) {
    // Handles null and undefined
    return true
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  return false
}

export const toNumber = (value: any): number => {
  const num = Number(value)
  return isNaN(num) ? 0 : num
}

export const toInteger = (value: any): number => {
  const num = parseInt(value, 10)
  return isNaN(num) ? 0 : num
}

interface MyObject {
  [key: string]: number | string // You can adjust the types based on your use case
}

export function sortBy<T extends MyObject>(
  data: T[],
  key: keyof T,
  ascending: boolean = true
): T[] {
  const order = ascending ? 1 : -1

  return [...data].sort((a, b) => {
    const valueA = a[key]
    const valueB = b[key]

    if (valueA < valueB) {
      return -1 * order
    } else if (valueA > valueB) {
      return 1 * order
    }

    return 0
  })
}

export type GroupedData<T> = Record<string, T[]>

function getKey<T>(item: T, key: string): string {
  // Handle the case where key includes nested properties or array index
  const keys = key.replace(/\[(\d+)\]/g, '.$1').split('.'); // Replace [index] with .index and split
  return keys.reduce((acc: any, k: any) => (acc && acc[k] !== undefined ? acc[k] : ''), item);
}

export function groupBy<T>(data: T[], key: string): GroupedData<T> {
  return data?.reduce((result: GroupedData<T>, item: T) => {
    // const groupKey = (item as any)[key] // Assuming key exists in each object
    const groupKey = getKey(item, key);
    if (!result[groupKey]) {
      result[groupKey] = []
    }
    result[groupKey].push(item)
    return result
  }, {})
}
