import memoize from 'lodash/memoize'

import config from './globalConfig'

import { clarity } from 'react-microsoft-clarity'

declare const window: any

export type RequestHeaders = { [key: string]: string }

export enum ContentType {
  JSON = 'JSON',
  FORM_URL_ENCODED = 'FORM_URL_ENCODED',
  MULTIPART_FORM_DATA = 'MULTIPART_FORM_DATA',
}

const HEADERS: { [key: string]: RequestHeaders } = {
  [ContentType.JSON]: {
    'Content-Type': 'application/json',
  },
  [ContentType.FORM_URL_ENCODED]: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  [ContentType.MULTIPART_FORM_DATA]: {
    'Content-Type': 'multipart/form-data',
  },
}

export const prepareHeaders = (headersFor: string[]): RequestHeaders => {
  let requestHeaders: RequestHeaders = {}
  headersFor.forEach((datum) => {
    const header = HEADERS[datum]
    requestHeaders = {
      ...requestHeaders,
      ...header,
    }
  })
  return requestHeaders
}

export const getAPIRootURLWithProtocol = memoize(
  (configAPIRoot: string): string => {
    if (!configAPIRoot) {
      return ''
    }

    const url = new URL(configAPIRoot)
    return url.toString()
  }
)

export const getApiUrlFromRoot = (url: string) => {
  return getAPIRootURLWithProtocol(config.API_ROOT) + 'damensch-bff/' + url
}

export const getHeaders = (headersFor: string[]): RequestHeaders => {
  return prepareHeaders(headersFor)
}

export const microsoftClarity = {
  // If needed, we can add more methods in this object.

  init: async (id: string) => {
    // Start seeing data on the Clarity dashboard with your id
    return await clarity.init(id)
  },
  identify: async (key: string, value: string) => {
    // Identify the user
    return await clarity.identify(key, { userProperty: value })
  },
  consent: async () => {
    // Cookie consent
    return await clarity.consent()
  },
  setTag: async (key: string, value: string) => {
    // Setup a custom tag
    return await clarity.setTag(key, value)
  },
  upgrade: async (upgradeReason: string) => {
    // Upgrade session
    return await clarity.upgrade(upgradeReason)
  },
  hasStarted: async () => {
    return await clarity.hasStarted()
  },
}
