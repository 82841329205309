import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { LocalStorage } from '@components/utils/payment-constants'

const BASE_URL = process.env.BETTERCOMMERCE_BASE_URL

const axiosInterceptorInstance = axios.create()

// Request interceptor
axiosInterceptorInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // Modify the request config here (add headers, authentication tokens)
    const accessToken = localStorage?.getItem(
      LocalStorage.Key.userEncryptedDetails
    ) // JSON.parse(localStorage?.getItem("token") || "");
    const user = localStorage?.getItem('user')
    const userDetails = user ? JSON.parse(user) : {}
    // If token is present, add it to request's Authorization Header
    if (accessToken) {
      if (config.headers) config.headers.auth_token = accessToken
    }
    if (userDetails?.userId) {
      if (config.headers) config.headers.userId = userDetails.userId
    }
    return config
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInterceptorInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify the response data here
    return response
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !location?.href?.includes('#loginOpen') &&
      !(Cookies?.get('askForLoginLogout') === 'true') &&
      localStorage?.getItem('user')
    ) {
      // Cookies.set(LocalStorage.Key.userEncryptedDetails, '')
      // localStorage.setItem(LocalStorage.Key.userEncryptedDetails, '')
      Cookies.set('askForLoginLogout', 'true')
      location?.reload()
    }
    return Promise.reject(error)
  }
)

export default axiosInterceptorInstance
