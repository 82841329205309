import { AxiosError } from 'axios'
import HttpError from 'standard-http-error'

export interface IHttpErrorProps {
  statusCode: number
  errorCode: number
  correlationId?: string
  message?: string
  timestamp?: number
}

export class DamenschHttpError extends HttpError {
  getErrorCode(): number {
    return this.errorCode
  }

  getCorrelationId(): string | undefined {
    return this.correlationId
  }

  constructor(
    httpStatusCode: number | string,
    message: string,
    props: IHttpErrorProps
  ) {
    super(httpStatusCode, message, {
      ...props,
      errorObj: props,
    })
  }
}

export const isHttpError = (arg: any): arg is DamenschHttpError => {
  if (arg instanceof DamenschHttpError) {
    return true
  }

  return false
}

export const prepareErrorPropsFromAxiosError = (
  error: AxiosError<{
    errorCode?: any
    message?: any
    correlationId?: any
    timestamp?: any
    data?: any
  }>
): IHttpErrorProps => {
  const response = error.response

  const responseData = error.response?.data

  const message = responseData?.message

  const errorProps: IHttpErrorProps = {
    statusCode: response?.status ?? 0,
    errorCode: responseData?.errorCode,
    message: message,
    correlationId: responseData?.correlationId,
    timestamp: responseData?.timestamp,
  }

  return errorProps
}

export default DamenschHttpError
