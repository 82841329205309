// Base Imports
import type { NextFetchEvent, NextRequest } from "next/server";
import { NextResponse } from "next/server";

// Other Imports
import redirections from "@framework/redirections.json";

const ALLOWED_REDIRECT_ORIGINS = [
    "/listings/",
    "/sale/",
    "/men/",
    "/product/",
    "/track-order",
    "/blogs",
    "/blog",
];

export const ALLOWED_UTM_QS = [
    "utm_id",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
];

export function middleware(req: NextRequest, ev: NextFetchEvent) {
    const uriParams = (uri: string) => {
        let url = decodeURI(uri);
        url = url.substring(url.indexOf("?") + 1);
        const params = url.replace('?', '')
            .split('&')
            .map(param => param.split('='))
            .reduce((values: any, [key, value]) => {
                values[key] = value;
                return values;
            }, {});

        return params;
    }

    //console.log(req.nextUrl)
    const pathname = decodeURIComponent(`${req?.nextUrl?.pathname}${req?.nextUrl?.search}`);

    if (!req?.nextUrl?.search) {
        const allowedOrigin = ALLOWED_REDIRECT_ORIGINS?.filter((origin: string) => pathname.startsWith(decodeURIComponent(origin)));
        if (pathname && allowedOrigin?.length) {

            if (redirections?.data && redirections?.data?.length) {

                const findRedirection = redirections?.data?.find((redirect: any) => decodeURIComponent(redirect?.originalURL)?.toLowerCase() === pathname);
                if (findRedirection) {
                    return NextResponse.redirect(
                        `${req.nextUrl.origin}${findRedirection?.newURL?.toLowerCase()}`
                    );
                } else {
                    return NextResponse.next();
                }

            }
        }
    } else {
        let ucmQuery = "";
        if (req?.nextUrl?.search) {
            const ucmQueryUri = uriParams(pathname);
            if (ucmQueryUri && Object.keys(pathname).length) {
                Object.keys(ucmQueryUri).forEach((key: string) => {
                    if (ALLOWED_UTM_QS.includes(key)) {
                        if (!ucmQuery) {
                            ucmQuery = `${key}=${ucmQueryUri[key]}`;
                        } else {
                            ucmQuery = `${ucmQuery}&${key}=${ucmQueryUri[key]}`;
                        }
                    }
                });
            }
        }

        let search = "";
        const allowedOrigin = ALLOWED_REDIRECT_ORIGINS?.filter((origin: string) => pathname.startsWith(decodeURIComponent(origin)));
        if (pathname && allowedOrigin?.length) {
            let matchPathName = pathname;
            const pathNameUri = uriParams(pathname);
            //console.log("pathNameUri", pathNameUri)
            if (pathNameUri && Object.keys(pathNameUri).length) {

                //const permissibleParams = Object.keys(pathNameUri).filter((x: string) => !ALLOWED_UTM_QS.includes(x));
                //if (permissibleParams && permissibleParams.length) {
                Object.keys(pathNameUri).forEach((key: string) => {
                    if (!ALLOWED_UTM_QS.includes(key) && pathNameUri[key]) {
                        if (!search) {
                            search = `?${key}=${pathNameUri[key]}`;
                        } else {
                            search = `${search}&${key}=${pathNameUri[key]}`;
                        }
                    }
                });
                //}

                matchPathName = `${req?.nextUrl?.pathname}${search}`;
            }

            //console.log("pathname", pathname);

            if (redirections?.data && redirections?.data?.length) {
                //console.log(matchPathName)
                const findRedirection = redirections?.data?.find((redirect: any) => decodeURIComponent(redirect?.originalURL)?.toLowerCase() === matchPathName);
                if (findRedirection) {
                    const resultantUrl = `${req.nextUrl.origin}${findRedirection?.newURL?.toLowerCase()}`;
                    //console.log("resultantUrl", resultantUrl);

                    if (ucmQuery) {
                        if (resultantUrl.indexOf("?") === -1) {
                            ucmQuery = `?${ucmQuery}`;
                        } else {
                            ucmQuery = `&${ucmQuery}`;
                        }
                        ucmQuery = `${ucmQuery}&redirect=true`;
                    }
                    //console.log("ucmQuery", ucmQuery);

                    return NextResponse.redirect(`${resultantUrl}${ucmQuery}`);
                } else {
                    return NextResponse.next();
                }
            }
        }
    }


    return NextResponse.next();
}
