import Script from "next/script"
import { useEffect, useState } from "react"
import { KP_MERCHANT_SCRIPT_BASE_URL, KP_MERCHANT_INFO } from '@framework/utils/constants'
import { tryParseJson } from "@framework/utils/parse-util"

declare const window: any
declare const kpUpdateDOM: any

export default function ({ onLoaded }: any) {
    const [isMerchantInfoSet, setIsMerchantInfoSet] = useState(false)
    const onReady = () => {
        if (typeof kpUpdateDOM !== 'undefined') {
            kpUpdateDOM();
        }

        if (onLoaded) onLoaded();
    }

    useEffect(() => {
        window.merchantInfo = tryParseJson(KP_MERCHANT_INFO)
        setIsMerchantInfoSet(true)
         window.Shopify = {
            shop: window.origin,
        };
    }, [])

    return (
        <>
            <Script id="non-shopify-core-functions" src={`${KP_MERCHANT_SCRIPT_BASE_URL}kwikpass/non-shopify-core-functions.min.js`} />
            {isMerchantInfoSet && <Script id="merchant-v2" src={`${KP_MERCHANT_SCRIPT_BASE_URL}kwikpass/plugin/build/kp-merchant-v2.js`} strategy="afterInteractive" onReady={onReady} />}
        </>
    )
}