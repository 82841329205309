export interface IPerformanceEntry extends PerformanceEntry {
  type?: string
}

export interface IUTM {
  utm_source: string
  utm_campaign: string
  utm_medium: string[]
}

export const utmObj: IUTM[] = [
  {
    utm_source: 'Social_Media',
    utm_campaign: 'Social_Media',
    utm_medium: ['youtube', 'instagram', 'facebook', 'linkedin', 'co', 'quora'],
  },
  {
    utm_source: 'Organic_Search',
    utm_campaign: 'Organic_Search',
    utm_medium: ['google', 'bing'],
  },
]
