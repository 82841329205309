import { getHeaders, getApiUrlFromRoot, RequestHeaders } from './utils'

export enum Environment {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
  MOCK = 'mock',
}

export interface IConfig {
  currentEnvironment: Environment
  API_ROOT: string
  getApiUrlFromRoot: (relativePath: string) => string
  getHeaders: (headersFor: string[]) => RequestHeaders
}

const config: IConfig = {
  currentEnvironment: process.env
    .NEXT_PUBLIC_MIDDLE_WARE_BASE_URL as Environment,
  API_ROOT: process.env.NEXT_PUBLIC_MIDDLE_WARE_BASE_URL ?? '',
  getApiUrlFromRoot,
  getHeaders,
}

export default config
